const load_ads = () => {
	if (window.AdSlot) window.AdSlot4.fillPlacements();
};

const ready = () => document.readyState === 'complete';

if (ready()) {
	load_ads();
} else {
	document.addEventListener('readystatechange', function handler() {
		if (ready()) {
			document.removeEventListener('readystatechange', handler);
			load_ads();
		}
	});
}
